import mp360_logo from '../assets/mp360_logo.svg';
import youtubeIcon from '../assets/youtube_icon.svg';
import instagramIcon from '../assets/instagram_icon.svg';
import facebookIcon from '../assets/facebook_icon.svg';
import linkedinIcon from '../assets/linkedin_icon.svg';
import grass from '../assets/grass.svg';
import Button from './Button/Button';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

function Footer() {
  let { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__details">
          <Link to={'/'}>
            <img
              src={mp360_logo}
              alt="merapashu logo"
              className="footer__details--logo"
            />
          </Link>
          <p className="footer__details--tag-line green">
            स्वस्थ पशु, समृद्ध किसान
          </p>
          <Button
              playStore = {true}
              text={t('download_app')}
              to = "https://play.google.com/store/search?q=merapashu360&c=apps"
              primary
          />

          <div className="footer__details--socials">
            <a
              href="https://www.youtube.com/@MeraPashu360"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={youtubeIcon}
                alt="youtubeIcon"
                className="footer__details--social-icon"
              />
            </a>
            <a
              href="https://www.facebook.com/merapashu360/"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={facebookIcon}
                alt="facebookIcon"
                className="footer__details--social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/merapashu_360/"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={instagramIcon}
                alt="instagramIcon"
                className="footer__details--social-icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/merapashu360"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={linkedinIcon}
                alt="linkedinIcon"
                className="footer__details--social-icon"
              />
            </a>
          </div>
        </div>
        <div className="footer__links">
          <div className="footer__menu">
            <div className="footer__menu--header">{t('company')}</div>
            <a href="/about-us" className="footer__menu--item">
              {t('about_us')}
            </a>
            <a href="https://www.linkedin.com/company/merapashu360/jobs/" className="footer__menu--item">
              {t('career')}
            </a>
            <a href="/contact-us" className="footer__menu--item">
              {t('contact_us')}
            </a>
            <a href="/faq" className="footer__menu--item">
              {t('faq')}
            </a>
            <a href="/blog" className="footer__menu--item">
            {t('blog')}
            </a>
            <a href="/know-more" className="footer__menu--item">
              {t('know_more')}
            </a>
          </div>
          <div className="footer__menu">
            <div className="footer__menu--header">{t('explore')}</div>
            <a href="/feed" className="footer__menu--item">
              {t('buy_cattle_feed')}
            </a>
            <a href="/buy-cattle" className="footer__menu--item">
              {t('buy_cattle')}
            </a>

            <a href="/sell-cattle" className="footer__menu--item">
              {t('sell_your_cattle')}
            </a>
          </div>
          <div className="footer__menu">
            <div className="footer__menu--header">{t('hub_loc')}</div>
            <div className="footer__menu--h-container">
              <div className="footer__menu--item--horizontal">
              <a href={"https://maps.app.goo.gl/n4r9vpzznn1pJZD39"} target="_blank" rel="noreferrer" className="footer__menu--item">
                  {t('gurgaon')}
              </a>
              </div>
            </div>
          </div>
          <div className="footer__menu">
            <div className="footer__menu--header">{t('delivering')}</div>
            <div className="footer__menu--h-container">
              <div className="footer__menu--item--horizontal">{t('delhi')}</div>
              <div className="footer__menu--item--horizontal">
                {t('haryana')}
              </div>
              <div className="footer__menu--item--horizontal">
                {t('punjab')}
              </div>
              <div className="footer__menu--item--horizontal">
                {t('rajasthan')}
              </div>
              <div className="footer__menu--item--horizontal">{t('up')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <p>
          &#169;
          <Trans
            i18nKey="copyright"
            values={{ year: new Date().getFullYear() }}
            components={[<strong>univers</strong>]}
          />
        </p>
        <div className="footer__tnc-links">
          <a href="/privacy-policies">{t('privacy_policy')}</a>
          <a href="/terms-of-service">{t('terms')}</a>
          <a href="/cancellation-policy">{t('cancellation_policy')}</a>
          <a href="/commerce-policy">{t('commerce_policy')}</a>
        </div>
        <img src={grass} alt="Grass" />
      </div>
    </div>
  );
}

export default Footer;
